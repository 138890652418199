import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditAmande = () => {
  const [plaque, setPlaque] = useState("");
  const [PTypeId, setPTypeId] = useState("");
  const [status, setStatus] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [penalties, setPenalties] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams(); // Retrieve the id parameter from the URL

  useEffect(() => {
    const fetchAmandeById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/amandes/${id}`);
        console.log('API Response:', response.data); // Log the response data
        const amande = response.data;
        setPlaque(amande.plaque);
        setPTypeId(amande.PTypeId);
        setStatus(amande.status !== undefined ? amande.status.toString() : "");
        setLat(amande.lat);
        setLong(amande.long);
        console.log('Form Values Set:', { plaque: amande.plaque, PTypeId: amande.PTypeId, status: amande.status, lat: amande.lat, long: amande.long });
      } catch (error) {
        console.error("Error fetching amande:", error);
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    fetchAmandeById();
  }, [id]);
  

  useEffect(() => {
    const fetchPenalties = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/penalties`);
        setPenalties(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPenalties();
  }, []);

  const updateAmande = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/amandes/${id}`, {
        plaque,
        PTypeId,
        status,
        lat,
        long,
      });
      navigate("/amandes");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Amandes</h1>
      <h2 className="subtitle">Modifier Amande</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateAmande}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Plaque</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={plaque}
                    onChange={(e) => setPlaque(e.target.value)}
                    placeholder="Numéro de Plaque"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Type d'Amande</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={PTypeId}
                      onChange={(e) => setPTypeId(e.target.value)}
                      required
                    >
                      <option value="">Sélectionnez un type d'amande</option>
                      {penalties.map((penalty) => (
                        <option key={penalty.id} value={penalty.id}>
                          {penalty.description} - {penalty.amount}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Status</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      required
                    >
                      <option value="0">Impayée</option>
                      <option value="1">Payée</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Latitude</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={lat}
                    onChange={(e) => setLat(e.target.value)}
                    placeholder="Latitude"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Longitude</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={long}
                    onChange={(e) => setLong(e.target.value)}
                    placeholder="Longitude"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditAmande;
