// components/FormAddVignette.jsx

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";

const FormAddVignette = () => {
  const [description, setDescription] = useState("");
  const [couleur, setCouleur] = useState("#fff"); // Default color
  const [montant, setMontant] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const saveVignette = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/vignettes`, {
        description,
        couleur,
        montant,
      });
      navigate("/vignettes");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Vignettes</h1>
      <h2 className="subtitle">Add New Vignette</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveVignette}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Description</label>
                <div className="control">
                  <div className="select is-fullwidth">
                  <select
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    >
                      <option value="">Select Description</option>
                      <option value="transport commun urbain">Transport Commun Urbain</option>
                      <option value="transport commun interurbain">Transport Commun Interurbain</option>
                      <option value="gros porteur">Gros Porteur</option>
                      <option value="camion">Camion</option>
                      <option value="bus de voyage">Bus de Voyage</option>
                      <option value="mini bus">Mini Bus</option>
                      <option value="taxi">Taxi</option>
                      <option value="vehicule">Vehicule Particulier</option>
                      <option value="moto_tricycle">Moto Tricycle</option>
                      <option value="moto">Moto</option>
                      <option value="TT">TT</option>
                      <option value="vip">VIP</option>
                    </select>                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Couleur</label>
                <div className="control">
                  <SketchPicker
                    color={couleur}
                    onChangeComplete={(color) => setCouleur(color.hex)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Montant</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={montant}
                    onChange={(e) => setMontant(e.target.value)}
                    placeholder="Montant"
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddVignette;
