import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import QRCode from 'qrcode.react';
import './PrintVignette.css';

const PrintVignette = () => {
  const { id } = useParams();
  const [vehiculeInfo, setVehiculeInfo] = useState(null);

  useEffect(() => {
    const fetchVehiculeInfo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/vehiculeinfos/${id}`);
        setVehiculeInfo(response.data);
      } catch (error) {
        console.error("Error fetching vehicule info:", error);
      }
    };

    fetchVehiculeInfo();
  }, [id]);

  const handlePrint = () => {
    window.print();
  };

  const getVitrefumeeStatus = (vitrefumee) => {
    return vitrefumee === "oui" ? "Autorisée" : "Non Autorisée";
  };

  return (
    <div>
      {vehiculeInfo ? (
        <div className="vignette-container">
          <div className="vignette">
            <div
              className="outer-circle"
              style={{ backgroundColor: vehiculeInfo.vignette.couleur }}
            ></div>
            <div className="inner-circle">
              <div className="logo-background"></div>
              {/* <p className="vignette-gsr">Groupement de Sécurité Routière</p> */}
              <p className="vignette-gsr"> REPUBLIQUE DU TCHAD</p>
              <p className="vignette-plaque">Plaque: {vehiculeInfo.plaque}</p>
              
              <div className="qr-code">
                <QRCode 
                  value={`Plaque: ${vehiculeInfo.plaque}, Marque: ${vehiculeInfo.marque}, Modele: ${vehiculeInfo.modele}, Chasie: ${vehiculeInfo.numero_de_chasie}, Type Assurance: ${vehiculeInfo.type_assurance}, Validite: ${vehiculeInfo.validite_assurance}, NNI du Proprietaire: ${vehiculeInfo.info_proprietaire} , Vitre Fumee: ${getVitrefumeeStatus(vehiculeInfo.vitrefumee)}`} 
                  size={80} 
                />
              </div>
            </div>
            <svg className="vignette-svg">
              <defs>
                <path
                  id="curve"
                  d="M 65, 100
                     A 100,90 0 0,1 270,120"
                />
                <path
                  id="curveBottom"
                  d="M 65, 140
                     A 100,90 0 0,0 270,300"
                />
              </defs>
              {/* <text width="500" className="vignette-title">
                <textPath href="#curve" startOffset="20%">
                  REPUBLIQUE DU TCHAD
                </textPath>
              </text> */}
              {/* <text width="500" className="vignette-title">
                <textPath href="#curveBottom" startOffset="32%">
                  Direction Générale de la Police Nationale
                </textPath>
              </text> */}
            </svg>
            <div className="vignette-title-arabic">جمهورية تشاد</div>
          </div>
          <button onClick={handlePrint} className="button is-primary no-print" style={{ marginTop: '20px' }}>
  Imprimer
</button>

        </div>
      ) : (
        <p>Loading...</p>
      )}
      <style>
        {`
          @media print {
            .no-print {
              display: none !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PrintVignette;
