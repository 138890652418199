import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditCommune = () => {
  const [denomination, setDenomination] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [superficie, setSuperficie] = useState("");
  const [nombreDeQuartier, setNombreDeQuartier] = useState("");
  const [departementId, setDepartementId] = useState("");
  const [departements, setDepartements] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getCommuneById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/communes/${id}`);
        setDenomination(response.data.denomination);
        setCodePostal(response.data.code_postal);
        setSuperficie(response.data.superficie);
        setNombreDeQuartier(response.data.nombre_de_quartier);
        setDepartementId(response.data.departementId);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getCommuneById();
  }, [id]);

  useEffect(() => {
    const fetchDepartements = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/departements`);
        setDepartements(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDepartements();
  }, []);

  const updateCommune = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/communes/${id}`, {
        denomination,
        code_postal: codePostal,
        superficie,
        nombre_de_quartier: nombreDeQuartier,
        departementId,
      });
      navigate("/communes");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Communes</h1>
      <h2 className="subtitle">Edit Commune</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateCommune}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Denomination</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={denomination}
                    onChange={(e) => setDenomination(e.target.value)}
                    placeholder="Denomination"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Code Postal</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codePostal}
                    onChange={(e) => setCodePostal(e.target.value)}
                    placeholder="Code Postal"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Superficie</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={superficie}
                    onChange={(e) => setSuperficie(e.target.value)}
                    placeholder="Superficie"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nombre de Quartier</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={nombreDeQuartier}
                    onChange={(e) => setNombreDeQuartier(e.target.value)}
                    placeholder="Nombre de Quartier"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Departement</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={departementId}
                      onChange={(e) => setDepartementId(e.target.value)}
                    >
                      <option value="">Select Departement</option>
                      {departements.map((departement) => (
                        <option key={departement.id} value={departement.id}>
                          {departement.denomination}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditCommune;
