import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Input, Pagination, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './VitreStatus.css';

const { Option } = Select;

const AmandeList = () => {
  const [amandes, setAmandes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filteredAmandes, setFilteredAmandes] = useState([]);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredAmandes.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    getAmandes();
  }, []);

  useEffect(() => {
    filterAmandes();
  }, [searchValue, amandes]);

  const getAmandes = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/amandes`);
    setAmandes(response.data);
  };

  const deleteAmande = async (amandeId) => {
    if (window.confirm("Are you sure that you want to delete?")) {
      await axios.delete(`${process.env.REACT_APP_API_URL}/amandes/${amandeId}`);
      getAmandes();
      toast.success('Amande deleted successfully');
    }
  };

  const filterAmandes = () => {
    const filteredData = amandes.filter((amande) =>
      Object.values(amande).some((field) =>
        field !== null && field !== undefined && field.toString().toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setFilteredAmandes(filteredData);
  };
  

  const handlePostsPerPageChange = (value) => {
    setPostsPerPage(value);
    setCurrentPage(1);
  };

  const renderStatus = (status) => {
    if (status === "0" || status === 0) {
      return (
        <span style={{ backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '5px' }}>
           Impayée
        </span>
      );
    } else if (status === "1" || status === 1) {
      return (
        <span style={{ backgroundColor: 'green', color: 'white', padding: '5px', borderRadius: '5px' }}>
          Payée
        </span>
      );
    } else {
      return <span>Unknown</span>;
    }
  };
  

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 className="title">Amandes</h1>
      <h2 className="subtitle">Liste des Amandes</h2>
      <Link to="/amandes/add" className="button is-primary mb-2">
        Ajouter Nouveau
      </Link>
      <Input
        placeholder="Rechercher par n'importe quel champ"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<SearchOutlined />}
        style={{ width: '220px', height: '38px', marginBottom: '16px', marginLeft: '8px' }}
      />

      <table className="table is-striped is-bordered  is-hoverable  is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Plaque</th>
            <th>Type d'Amande</th>
            <th>Montant</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((amande, index) => (
            <tr key={amande.id}>
              <td>{index + firstPostIndex + 1}</td>
              <td>{amande.plaque}</td>
              <td>{amande.penalty.description}</td>
              <td>{amande.penalty.amount}</td>
              <td>{renderStatus(amande.status)}</td>
              <td>
                <Link
                  to={`/amandes/edit/${amande.id}`}
                  className="button is-small is-info mr-1"
                >
                  Modifier
                </Link>
                <button
                  onClick={() => deleteAmande(amande.id)}
                  className="button is-small is-danger mr-1"
                >
                  Supprimer
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <div style={{ marginRight: '8px' }}>
          <Select
            value={postsPerPage}
            onChange={handlePostsPerPageChange}
            style={{ width: '80px' }}
          >
            <Option value={10}>10</Option>
            <Option value={25}>25</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Pagination
            current={currentPage}
            pageSize={postsPerPage}
            total={filteredAmandes.length}
            onChange={(page) => setCurrentPage(page)}
            style={{ textAlign: 'right' }}
          />
        </div>
      </div>
    </div>
  );
};

export default AmandeList;
