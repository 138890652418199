import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddVehicule from "./pages/AddVehicule";
import EditVehicule from "./pages/EditVehicule";
import Vehicules from "./pages/Vehicules";
import VitreFumees from "./pages/VitreFumees";
import AddVitreFumee from "./pages/AddVitreFumee";
import EditVitreFumee from "./pages/EditVitreFumee";
import AddPenalty from './pages/AddPenalty';
import Penalties from './pages/Penalties';
import EditPenalty from './pages/EditPenalty';
import PoliceStations from './pages/PoliceStations';
import AddPoliceStation from './pages/AddPoliceStation';
import EditPoliceStaion from './pages/EditPoliceStation';
import Account from './pages/Account';
import ViewVitreFumee from './components/ViewVitreFumee';
import ViewVitreFumeeOne from './pages/ViewVitreFumeeOne';
import Provinces from './pages/Provinces';
import AddProvince from './pages/AddProvince';
import EditProvince from './pages/EditProvince';
import Departements from './pages/Departements';
import AddDepartement from './pages/AddDepartement';
import EditDepartement from './pages/EditDepartement';
import Communes from './pages/Communes';
import AddCommune from './pages/AddCommune';
import EditCommune from './pages/EditCommune';
import Quartiers from './pages/Quartiers';
import AddQuartier from './pages/AddQuartier';
import EditQuartier from './pages/EditQuartier';
import CommuneList from './components/CommuneList';
import QuartierList from './components/QuartierList';
import Rues from './pages/Rues';
import AddRue from './pages/AddRue';
import EditRue from './pages/EditRue';
import Portes from './pages/Portes';
import AddPorte from './pages/AddPorte';
import EditPorte from './pages/EditPorte';
import PersonalInfos from './pages/PersonalInfos';
import AddPersonalInfo from './pages/AddPersonalInfo';
import EditPersonalInfo from './pages/EditPersonalInfo';
import AddVignette from './pages/AddVignette';
import EditVignette from './pages/EditVignette';
import Vignettes from './pages/Vignettes';
import VehiculeInfos from './pages/VehiculeInfos';
import AddVehiculeInfo from './pages/AddVehiculeInfo';
import PrintVignette from './components/PrintVignette';
import EditVehiculeInfo from './pages/EditVehiculeInfo';
import VehiculeInfoHistory from './components/VehiculeInfoHistory';
import AddAmande from './pages/AddAmande';
import Amandes from './pages/Amandes';
import EditAmande from './pages/EditAmande';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/account" element={<Account />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />
          <Route path="/vehicules" element={<Vehicules />} />
          <Route path="/vehicules/add" element={<AddVehicule />} />
          <Route path="/vehicules/edit/:id" element={<EditVehicule />} />
          <Route path="/vitreFumees" element={<VitreFumees />} />
          <Route path="/vitreFumees/add" element={<AddVitreFumee />} />
          <Route path="/vitreFumees/edit/:id" element={<EditVitreFumee />} />
          <Route path="/vitreFumees/view/:id" element={<ViewVitreFumeeOne />} />
          <Route path="/penalties/add" element={<AddPenalty />} />
          <Route path="/penalties" element={<Penalties />} />
          <Route path="/penalties/edit/:id" element={<EditPenalty />} />
          <Route path="/amandes/edit/:id" element={<Amandes />} />
          <Route path="/policeStations/add" element={<AddPoliceStation />} />
          <Route path="/policeStations/edit/:id" element={<EditPoliceStaion />} />
          <Route path="/policeStations" element={<PoliceStations />} />
          <Route path="/provinces" element={<Provinces />} />
          <Route path="/provinces/add" element={<AddProvince />} />
          <Route path="/provinces/edit/:id" element={<EditProvince />} />
          <Route path="/departements" element={<Departements />} />
          <Route path="/departements/add" element={<AddDepartement />} />
          <Route path="/departements/edit/:id" element={<EditDepartement />} />
          <Route path="/communes" element={<Communes />} />
          <Route path="/communes/add" element={<AddCommune />} />
          <Route path="/communes/edit/:id" element={<EditCommune />} />
          <Route path="/quartiers" element={<Quartiers />} />
          <Route path="/quartiers/add" element={<AddQuartier />} />
          <Route path="/quartiers/edit/:id" element={<EditQuartier />} />
          <Route path="/rues" element={<Rues />} />
          <Route path="/rues/add" element={<AddRue />} />
          <Route path="/rues/edit/:id" element={<EditRue />} />
          <Route path="/portes" element={<Portes />} />
          <Route path="/portes/add" element={<AddPorte />} />
          <Route path="/portes/edit/:id" element={<EditPorte />} />
          <Route path="/personalinfos" element={<PersonalInfos />} />
          <Route path="/personalinfos/add" element={<AddPersonalInfo />} />
          <Route path="/personalinfos/edit/:id" element={<EditPersonalInfo />} />
          <Route path="/vignettes" element={<Vignettes />} />
          <Route path="/vignettes/add" element={<AddVignette />} />
          <Route path="/vignettes/edit/:id" element={<EditVignette />} />
          <Route path="/vehiculeinfos" element={<VehiculeInfos />} />
          <Route path="/vehiculeinfos/add" element={<AddVehiculeInfo />} />
          <Route path="/vehiculeinfos/edit/:id" element={<EditVehiculeInfo />} />
          {/* <Route path="/vignettes/edit/:id" element={<EditVignette />} /> */}
          <Route path="/print-vignette/:id" element={<PrintVignette />} />
          <Route path="/vehiculeinfos/:id/history" element={<VehiculeInfoHistory />} />
          <Route path="/amandes" element={<Amandes />} />
          <Route path="/amandes/add" element={<AddAmande />} />
          <Route path="/amandes/edit/:id" element={<EditAmande />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
