import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Input, Pagination, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './VitreStatus.css';

const { Option } = Select;

const ProvinceList = () => {
  const [provinces, setProvinces] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filteredProvinces, setFilteredProvinces] = useState([]);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredProvinces.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    filterProvinces();
  }, [searchValue, provinces]);

  const getProvinces = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/provinces`);
    setProvinces(response.data);
  };

  const deleteProvince = async (provinceId) => {
    if (window.confirm("Are you sure that you want to delete?")) {
      await axios.delete(`${process.env.REACT_APP_API_URL}/provinces/${provinceId}`);
      getProvinces();
      console.log(provinces);
    }
  };

  const filterProvinces = () => {
    const filteredData = provinces.filter((province) =>
      Object.values(province).some((field) =>
        field.toString().toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setFilteredProvinces(filteredData);
  };

  const handlePostsPerPageChange = (value) => {
    setPostsPerPage(value);
    setCurrentPage(1);
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 className="title">Provinces</h1>
      <h2 className="subtitle">Liste des provinces</h2>
      <Link to="/provinces/add" className="button is-primary mb-2">
      Ajouter un nouveau
      </Link>
      <Input
        placeholder="Search by any field"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<SearchOutlined />}
        style={{ width: '220px', height: '38px', marginBottom: '16px', marginLeft: '8px' }}
      />

      <table className="table is-striped is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Code</th>
            <th>Denomination</th>
            <th>Code ISO</th>
            <th>Code Postal</th>
            <th>Superficie</th>
            <th>Nombre de Departement</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((province, index) => (
            <tr key={province.id}>
              <td>{index + firstPostIndex + 1}</td>
              <td>{province.code}</td>
              <td>{province.denomination}</td>
              <td>{province.code_iso}</td>
              <td>{province.code_postal}</td>
              <td>{province.superficie}</td>
              <td>{province.nombre_de_departement}</td>
              <td>
                <Link
                  to={`/provinces/edit/${province.id}`}
                  className="button is-small is-info mr-1"
                >
                 Modifier
                </Link>
                <button
                  onClick={() => deleteProvince(province.id)}
                  className="button is-small is-danger mr-1"
                >
              Supprimer
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <div style={{ marginRight: '8px' }}>
          <Select
            value={postsPerPage}
            onChange={handlePostsPerPageChange}
            style={{ width: '80px' }}
          >
            <Option value={10}>10</Option>
            <Option value={25}>25</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Pagination
            current={currentPage}
            pageSize={postsPerPage}
            total={filteredProvinces.length}
            onChange={(page) => setCurrentPage(page)}
            style={{ textAlign: 'right' }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProvinceList;
