import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditProvince = () => {
  const [code, setCode] = useState("");
  const [denomination, setDenomination] = useState("");
  const [codeIso, setCodeIso] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [superficie, setSuperficie] = useState("");
  const [nombreDeDepartement, setNombreDeDepartement] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getProvinceById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/provinces/${id}`);
        setCode(response.data.code);
        setDenomination(response.data.denomination);
        setCodeIso(response.data.code_iso);
        setCodePostal(response.data.code_postal);
        setSuperficie(response.data.superficie);
        setNombreDeDepartement(response.data.nombre_de_departement);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getProvinceById();
  }, [id]);

  const updateProvince = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/provinces/${id}`, {
        code: code,
        denomination: denomination,
        code_iso: codeIso,
        code_postal: codePostal,
        superficie: superficie,
        nombre_de_departement: nombreDeDepartement,
      });
      navigate("/provinces");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Provinces</h1>
      <h2 className="subtitle">Edit Province</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateProvince}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Code</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Code"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Denomination</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={denomination}
                    onChange={(e) => setDenomination(e.target.value)}
                    placeholder="Denomination"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Code ISO</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codeIso}
                    onChange={(e) => setCodeIso(e.target.value)}
                    placeholder="Code ISO"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Code Postal</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codePostal}
                    onChange={(e) => setCodePostal(e.target.value)}
                    placeholder="Code Postal"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Superficie</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={superficie}
                    onChange={(e) => setSuperficie(e.target.value)}
                    placeholder="Superficie"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nombre de Departement</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={nombreDeDepartement}
                    onChange={(e) => setNombreDeDepartement(e.target.value)}
                    placeholder="Nombre de Departement"
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditProvince;
