import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditRue = () => {
  const [denomination, setDenomination] = useState("");
  const [typeDeRue, setTypeDeRue] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [quartierId, setQuartierId] = useState("");
  const [quartiers, setQuartiers] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getRueById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/rues/${id}`);
        setDenomination(response.data.denomination);
        setTypeDeRue(response.data.type_de_rue);
        setLongitude(response.data.longitude);
        setLatitude(response.data.latitude);
        setQuartierId(response.data.quartierId);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getRueById();
  }, [id]);

  useEffect(() => {
    const fetchQuartiers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/quartiers`);
        setQuartiers(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuartiers();
  }, []);

  const updateRue = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/rues/${id}`, {
        denomination,
        type_de_rue: typeDeRue,
        longitude,
        latitude,
        quartierId,
      });
      navigate("/rues");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Rues</h1>
      <h2 className="subtitle">Edit Rue</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateRue}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Denomination</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={denomination}
                    onChange={(e) => setDenomination(e.target.value)}
                    placeholder="Denomination"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Type de Rue</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={typeDeRue}
                      onChange={(e) => setTypeDeRue(e.target.value)}
                    >
                      <option value="">Select Type de Rue</option>
                      <option value="Avenue">Avenue</option>
                      <option value="Autoroute">Autoroute</option>
                      <option value="Barriere">Barriere</option>
                      <option value="Rue">Rue</option>
                      <option value="Boulevard">Boulevard</option>
                      <option value="Carrefour">Carrefour</option>
                      <option value="Autre">Autre</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Longitude</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    placeholder="Longitude"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Latitude</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    placeholder="Latitude"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Quartier</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={quartierId}
                      onChange={(e) => setQuartierId(e.target.value)}
                    >
                      <option value="">Select Quartier</option>
                      {quartiers.map((quartier) => (
                        <option key={quartier.id} value={quartier.id}>
                          {quartier.denomination}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditRue;
