import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoPerson, IoHome, IoLogOut, IoCashOutline } from "react-icons/io5";
import { AiOutlineCar, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import styled from "styled-components";

const StyleSideBar = styled.aside`
  padding: 2rem 1rem; /* Adjust padding to reduce size */
  border-right: 1px solid var(--color-grey-100);
  grid-row: 1 / -1;
  font-family: "Arial", sans-serif;
`;

const MenuItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1.4rem; /* Adjust font size to reduce size */
`;

const MenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 0.9rem; /* Adjust font size to reduce size */

  &:hover {
    color: gray;
  }

  &.active {
    color: blue;
  }
`;

const Icon = styled.span`
  margin-right: 0.5rem;
  font-size: 1.2rem; /* Adjust icon size to reduce size */
`;

const DropdownItem = styled.li`
  margin-bottom: 0.5rem;
  font-size: 1.2rem; /* Adjust font size to reduce size */
  padding-left: 1.2rem;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem; /* Adjust font size to reduce size */
`;

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <div className="sidebar">
      <StyleSideBar>
        <ul className="menu-list">
          <MenuItem>
            <MenuLink to={"/dashboard"}>
              <Icon>
                <IoHome />
              </Icon>
              Dashboard
            </MenuLink>
          </MenuItem>

          <MenuItem>
            <DropdownHeader onClick={() => toggleDropdown('organisation')}>
              <Icon>
                <AiOutlineCar />
              </Icon>
              Organisation Territoriale
              <Icon style={{ marginLeft: 'auto' }}>
                {activeDropdown === 'organisation' ? <AiOutlineUp /> : <AiOutlineDown />}
              </Icon>
            </DropdownHeader>
            {activeDropdown === 'organisation' && (
              <ul style={{ paddingLeft: '1.5rem' }}>
                <DropdownItem>
                  <MenuLink to={"/provinces"}>
                    Provinces
                  </MenuLink>
                </DropdownItem>
                <DropdownItem>
                  <MenuLink to={"/departements"}>
                    Departements
                  </MenuLink>
                </DropdownItem>
                <DropdownItem>
                  <MenuLink to={"/communes"}>
                    Communes
                  </MenuLink>
                </DropdownItem>
                <DropdownItem>
                  <MenuLink to={"/quartiers"}>
                    Quartier
                  </MenuLink>
                </DropdownItem>
                <DropdownItem>
                  <MenuLink to={"/rues"}>
                    Rues
                  </MenuLink>
                </DropdownItem>
                <DropdownItem>
                  <MenuLink to={"/portes"}>
                    Portes
                  </MenuLink>
                </DropdownItem>
              </ul>
            )}
          </MenuItem>

          <MenuItem>
            <MenuLink to={"/personalinfos"}>
              <Icon>
              <IoPerson />
              </Icon>
              Organisation Citoyenne
            </MenuLink>
          </MenuItem>

          <MenuItem>
            <DropdownHeader onClick={() => toggleDropdown('groupement')}>
              <Icon>
                <AiOutlineCar />
              </Icon>
              Groupement de Securite Routiere
              <Icon style={{ marginLeft: 'auto' }}>
                {activeDropdown === 'groupement' ? <AiOutlineUp /> : <AiOutlineDown />}
              </Icon>
            </DropdownHeader>
            {activeDropdown === 'groupement' && (
              <ul style={{ paddingLeft: '1.5rem' }}>
                  <DropdownItem>
                  <MenuLink to={"/vehiculeinfos"}>
                    Vehicule
                  </MenuLink>
                </DropdownItem>
                <DropdownItem>
                  <MenuLink to={""}>
                   Payement Amende
                  </MenuLink>
                  <DropdownItem>
                  <MenuItem>
                <MenuLink to={"/penalties"}>
                  <Icon>
                    <IoCashOutline />
                  </Icon>
                   Type D'AMENDE
                </MenuLink>
              </MenuItem>
                </DropdownItem>
                <DropdownItem>
                <MenuItem>
            <MenuLink to={"/amandes"}>
              <Icon>
                <IoCashOutline />
              </Icon>
              Amendes
            </MenuLink>
          </MenuItem>
                </DropdownItem>
                </DropdownItem>
                <DropdownItem>
                  <MenuLink to={""}>
                    Vignette
                  </MenuLink>
                  <DropdownItem>
                  <MenuLink to={"/vignettes"}>
                    Categorie Vignette
                  </MenuLink>
                </DropdownItem>
                <DropdownItem>
                  <MenuLink to={"/provinces"}>
                    Payement Vignette
                  </MenuLink>
                </DropdownItem>
                </DropdownItem>
               
              </ul>
            )}
          </MenuItem>
        </ul>

      
        {user && (user.role === "admin" || user.role === "dg" || user.role === "combsr") && (
          <div>
            <ul className="menu-list">
              <MenuItem>
                <MenuLink to={"/users"}>
                  <Icon>
                    <IoPerson />
                  </Icon>
                  Users
                </MenuLink>
              </MenuItem>
          
            </ul>
          </div>
        )}
      </StyleSideBar>
    </div>
  );
};

export default Sidebar;
