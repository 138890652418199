import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input, Pagination, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux'; // Import useSelector to get role from Redux state

const { Option } = Select;

const VehiculeInfoList = () => {
    const userRole = useSelector((state) => {
        return state.auth.user && state.auth.user.role;
      });
  const [vehiculeInfos, setVehiculeInfos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filteredVehiculeInfos, setFilteredVehiculeInfos] = useState([]);
  const navigate = useNavigate();

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredVehiculeInfos.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    getVehiculeInfos();
  }, []);

  useEffect(() => {
    filterVehiculeInfos();
  }, [searchValue, vehiculeInfos]);

  const getVehiculeInfos = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/vehiculeinfos`);
    setVehiculeInfos(response.data);
  };

  const deleteVehiculeInfo = async (vehiculeInfoId) => {
    if (window.confirm('Are you sure that you want to delete?')) {
      await axios.delete(`${process.env.REACT_APP_API_URL}/${vehiculeInfoId}`);
      getVehiculeInfos();
      toast.success('Vehicle information deleted successfully');
    }
  };

  const filterVehiculeInfos = () => {
    const filteredData = vehiculeInfos.filter((vehiculeInfo) =>
      Object.values(vehiculeInfo).some((field) =>
        field ? field.toString().toLowerCase().includes(searchValue.toLowerCase()) : false
      )
    );
    setFilteredVehiculeInfos(filteredData);
  };

  const handlePostsPerPageChange = (value) => {
    setPostsPerPage(value);
    setCurrentPage(1);
  };

  const printVignette = (vehiculeInfo) => {
    navigate(`/print-vignette/${vehiculeInfo.id}`);
  };

  const viewHistory = (vehiculeInfo) => {
    navigate(`/vehiculeinfos/${vehiculeInfo.id}/history`);
  };

  const getVitrefumeeStatus = (vitrefumee) => {
    return vitrefumee === "oui" ? (
      <span style={{ backgroundColor: 'green', color: 'white', padding: '5px', borderRadius: '5px' }}>
        Autorisée
      </span>
    ) : (
      <span style={{ backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '5px' }}>
        Non Autorisée
      </span>
    );
  };

  return (
    <div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <h1 className="title">Informations sur le véhicule</h1>
      <h2 className="subtitle">Liste des informations sur le véhicule</h2>
      <Link to="/vehiculeinfos/add" className="button is-primary mb-2">
        Ajouter Nouveau
      </Link>
      <Input
        placeholder="Search by any field"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<SearchOutlined />}
        style={{ width: '220px', height: '38px', marginBottom: '16px', marginLeft: '8px' }}
      />

      <table className="table is-striped is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Plaque</th>
            {/* <th>Genre</th>
            <th>Type</th>
            <th>Marque</th>
            <th>Modèle</th> */}
            <th>Vitre Fumee</th>
         
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((vehiculeInfo, index) => (
            <tr key={vehiculeInfo.id}>
              <td>{index + firstPostIndex + 1}</td>
              <td>{vehiculeInfo.plaque}</td>
              {/* <td>{vehiculeInfo.genre}</td>
              <td>{vehiculeInfo.type}</td>
              <td>{vehiculeInfo.marque}</td>
              <td>{vehiculeInfo.modele}</td> */}
              <td>{getVitrefumeeStatus(vehiculeInfo.vitrefumee)}</td>
             
              <td>
                <Link
                  to={`/vehiculeinfos/edit/${vehiculeInfo.id}`}
                  className="button is-small is-info mr-1"
                >
                  Modifier
                </Link>
                {userRole === 'admin' && ( // Conditionally render the delete button for admins only
                  <button
                    onClick={() => deleteVehiculeInfo(vehiculeInfo.id)}
                    className="button is-small is-danger mr-1"
                  >
                    Supprimer
                  </button>
                )}
                <button
                  onClick={() => printVignette(vehiculeInfo)}
                  className="button is-small is-primary mr-1"
                >
                  Vignette
                </button>
                <button
                  onClick={() => viewHistory(vehiculeInfo)}
                  className="button is-small is-warning mr-1"
                >
                  Voir l'historique
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <div style={{ marginRight: '8px' }}>
          <Select
            value={postsPerPage}
            onChange={handlePostsPerPageChange}
            style={{ width: '80px' }}
          >
            <Option value={10}>10</Option>
            <Option value={25}>25</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Pagination
            current={currentPage}
            pageSize={postsPerPage}
            total={filteredVehiculeInfos.length}
            onChange={(page) => setCurrentPage(page)}
            style={{ textAlign: 'right' }}
          />
        </div>
      </div>
    </div>
  );
};

export default VehiculeInfoList;
