import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditQuartier = () => {
  const [denomination, setDenomination] = useState("");
  const [superficie, setSuperficie] = useState("");
  const [nombreDeRue, setNombreDeRue] = useState("");
  const [communeId, setCommuneId] = useState("");
  const [communes, setCommunes] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getQuartierById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/quartiers/${id}`);
        setDenomination(response.data.denomination);
        setSuperficie(response.data.superficie);
        setNombreDeRue(response.data.nombre_de_rue);
        setCommuneId(response.data.communeId);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getQuartierById();
  }, [id]);

  useEffect(() => {
    const fetchCommunes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/communes`);
        setCommunes(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCommunes();
  }, []);

  const updateQuartier = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/quartiers/${id}`, {
        denomination,
        superficie,
        nombre_de_rue: nombreDeRue,
        communeId,
      });
      navigate("/quartiers");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Quartiers</h1>
      <h2 className="subtitle">Edit Quartier</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateQuartier}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Denomination</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={denomination}
                    onChange={(e) => setDenomination(e.target.value)}
                    placeholder="Denomination"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Superficie</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={superficie}
                    onChange={(e) => setSuperficie(e.target.value)}
                    placeholder="Superficie"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nombre de Rue</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={nombreDeRue}
                    onChange={(e) => setNombreDeRue(e.target.value)}
                    placeholder="Nombre de Rue"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Commune</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={communeId}
                      onChange={(e) => setCommuneId(e.target.value)}
                    >
                      <option value="">Select Commune</option>
                      {communes.map((commune) => (
                        <option key={commune.id} value={commune.id}>
                          {commune.denomination}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditQuartier;
