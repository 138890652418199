import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Input, Pagination, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './VitreStatus.css'

const { Option } = Select;

const PenaltyList = () => {
  const [penalties, setPenalties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filteredPenalties, setFilteredPenalties] = useState([]);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredPenalties.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    getPenalties();
  }, []);

  useEffect(() => {
    filterPenalties();
  }, [searchValue, penalties]);

  const getPenalties = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/penalties`);
      setPenalties(response.data);
      setFilteredPenalties(response.data);
    } catch (error) {
      console.error("Error fetching penalties:", error);
      toast.error("Failed to fetch penalties");
    }
  };

  const deletePenalty = async (penaltyId) => {
    if (window.confirm("Are you sure you want to delete this penalty?")) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/penalties/${penaltyId}`);
        toast.success("Penalty deleted successfully");
        getPenalties(); // Refresh the penalties list
      } catch (error) {
        console.error("Error deleting penalty:", error);
        toast.error("Failed to delete penalty");
      }
    }
  };

  const filterPenalties = () => {
    const filteredData = penalties.filter((penalty) =>
      Object.values(penalty).some((field) =>
        field && field.toString().toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setFilteredPenalties(filteredData);
  };

  const handlePostsPerPageChange = (value) => {
    setPostsPerPage(value);
    setCurrentPage(1);
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 className="title">Penalties</h1>
      <h2 className="subtitle">List Of Penalties</h2>
      <Link to="/penalties/add" className="button is-primary mb-2">
        Add New
      </Link>
      <Input
        placeholder="Search by any field"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<SearchOutlined />}
        style={{ width: '220px', height: '38px', marginBottom: '16px', marginLeft: '8px' }}
      />

      <table className="table is-striped is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Description</th>
            <th>Amount</th>
            {/* <th>Created By</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((penalty, index) => (
            <tr key={penalty.id}>
              <td>{index + firstPostIndex + 1}</td>
              <td>{penalty.description}</td>
              <td>{penalty.amount}</td>
              {/* <td>{penalty.user ? penalty.user.name : "N/A"}</td> */}
              <td>
                <Link
                  to={`/penalties/edit/${penalty.id}`}
                  className="button is-small is-info mr-1"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deletePenalty(penalty.id)}
                  className="button is-small is-danger mr-1"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <div style={{ marginRight: '8px' }}>
          <Select
            value={postsPerPage}
            onChange={handlePostsPerPageChange}
            style={{ width: '80px' }}
          >
            <Option value={10}>10</Option>
            <Option value={25}>25</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Pagination
            current={currentPage}
            pageSize={postsPerPage}
            total={filteredPenalties.length}
            onChange={(page) => setCurrentPage(page)}
            style={{ textAlign: 'right' }}
          />
        </div>
      </div>
    </div>
  );
};

export default PenaltyList;
