import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Input, Pagination, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './VitreStatus.css';

const { Option } = Select;

const PersonalInfoList = () => {
  const [personalInfos, setPersonalInfos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filteredPersonalInfos, setFilteredPersonalInfos] = useState([]);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredPersonalInfos.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    getPersonalInfos();
  }, []);

  useEffect(() => {
    filterPersonalInfos();
  }, [searchValue, personalInfos]);

  const getPersonalInfos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/personalinfos`);
      setPersonalInfos(response.data);
    } catch (error) {
      toast.error("Error fetching personal infos");
    }
  };

  const deletePersonalInfo = async (personalInfoId) => {
    if (window.confirm("Are you sure that you want to delete?")) {
      await axios.delete(`${process.env.REACT_APP_API_URL}/personalinfos/${personalInfoId}`);
      getPersonalInfos();
    }
  };

  const filterPersonalInfos = () => {
    const filteredData = personalInfos.filter((personalInfo) =>
      Object.values(personalInfo).some((field) =>
        field && field.toString().toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setFilteredPersonalInfos(filteredData);
  };

  const handlePostsPerPageChange = (value) => {
    setPostsPerPage(value);
    setCurrentPage(1);
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 className="title">Informations personnelles</h1>
      <h2 className="subtitle">Liste des informations personnelles</h2>
      <Link to="/personalinfos/add" className="button is-primary mb-2">
      Ajouter un nouveau
      </Link>
      <Input
        placeholder="Search by any field"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<SearchOutlined />}
        style={{ width: '220px', height: '38px', marginBottom: '16px', marginLeft: '8px' }}
      />

      <table className="table is-striped is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>NNI</th>
            <th>Nom</th>
            <th>Date de naissance</th>
            <th>Lieu de Naissance</th>
            <th>Numéro de Téléphone</th>
            <th>Numéro de Carte</th>
            <th>Profession</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((personalInfo, index) => (
            <tr key={personalInfo.id}>
              <td>{index + firstPostIndex + 1}</td>
              <td>{personalInfo.nni}</td>
              <td>{personalInfo.nom}</td>
              <td>{personalInfo.dob}</td>
              <td>{personalInfo.lieu_de_naissance}</td>
              <td>{personalInfo.numero_de_telephone}</td>
              <td>{personalInfo.numero_de_carte}</td>
              <td>{personalInfo.profession}</td>
              <td>
                {personalInfo.province && personalInfo.province.denomination}, 
                {personalInfo.departement && personalInfo.departement.denomination}, 
                {personalInfo.commune && personalInfo.commune.denomination}, 
                {personalInfo.quartier && personalInfo.quartier.denomination}, 
                {personalInfo.rue && personalInfo.rue.denomination}, 
                {personalInfo.porte && personalInfo.porte.numero_du_porte}
              </td>
              <td>
                <Link
                  to={`/personalinfos/edit/${personalInfo.id}`}
                  className="button is-small is-info mr-1"
                >
                Modifier
                </Link>
                <button
                  onClick={() => deletePersonalInfo(personalInfo.id)}
                  className="button is-small is-danger mr-1"
                >
                 Supprimer
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <div style={{ marginRight: '8px' }}>
          <Select
            value={postsPerPage}
            onChange={handlePostsPerPageChange}
            style={{ width: '80px' }}
          >
            <Option value={10}>10</Option>
            <Option value={25}>25</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Pagination
            current={currentPage}
            pageSize={postsPerPage}
            total={filteredPersonalInfos.length}
            onChange={(page) => setCurrentPage(page)}
            style={{ textAlign: 'right' }}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoList;
