import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddPorte = () => {
  const [numeroDuPorte, setNumeroDuPorte] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [rueId, setRueId] = useState("");
  const [rues, setRues] = useState([]);
  const [nomDuProprietaire, setNomDuProprietaire] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchRues();
  }, []);

  const fetchRues = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/rues`);
      setRues(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const savePorte = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/portes`, {
        numero_du_porte: numeroDuPorte,
        longitude,
        latitude,
        rueId,
        nom_du_proprietaire: nomDuProprietaire,
      });
      navigate("/portes");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Portes</h1>
      <h2 className="subtitle">Add New Porte</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={savePorte}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Numéro du Porte</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={numeroDuPorte}
                    onChange={(e) => setNumeroDuPorte(e.target.value)}
                    placeholder="Numéro du Porte"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Longitude</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    placeholder="Longitude"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Latitude</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    placeholder="Latitude"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nom du Propriétaire</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={nomDuProprietaire}
                    onChange={(e) => setNomDuProprietaire(e.target.value)}
                    placeholder="Nom du Propriétaire"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Rue</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={rueId}
                      onChange={(e) => setRueId(e.target.value)}
                      required
                    >
                      <option value="">Select Rue</option>
                      {rues.map((rue) => (
                        <option key={rue.id} value={rue.id}>
                          {rue.denomination}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddPorte;
