import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditDepartement = () => {
  const [denomination, setDenomination] = useState("");
  const [nombreDeCommune, setNombreDeCommune] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [superficie, setSuperficie] = useState("");
  const [provinceId, setProvinceId] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getDepartementById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/departements/${id}`);
        setDenomination(response.data.denomination);
        setNombreDeCommune(response.data.nombre_de_commune);
        setCodePostal(response.data.code_postal);
        setSuperficie(response.data.superficie);
        setProvinceId(response.data.provinceId);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getDepartementById();
  }, [id]);

  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/provinces`);
        setProvinces(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProvinces();
  }, []);

  const updateDepartement = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/departements/${id}`, {
        denomination,
        nombre_de_commune: nombreDeCommune,
        code_postal: codePostal,
        superficie,
        provinceId,
      });
      navigate("/departements");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Departements</h1>
      <h2 className="subtitle">Edit Departement</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateDepartement}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Denomination</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={denomination}
                    onChange={(e) => setDenomination(e.target.value)}
                    placeholder="Denomination"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nombre de Commune</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={nombreDeCommune}
                    onChange={(e) => setNombreDeCommune(e.target.value)}
                    placeholder="Nombre de Commune"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Code Postal</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codePostal}
                    onChange={(e) => setCodePostal(e.target.value)}
                    placeholder="Code Postal"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Superficie</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={superficie}
                    onChange={(e) => setSuperficie(e.target.value)}
                    placeholder="Superficie"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Province</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={provinceId}
                      onChange={(e) => setProvinceId(e.target.value)}
                    >
                      <option value="">Select Province</option>
                      {provinces.map((province) => (
                        <option key={province.id} value={province.id}>
                          {province.denomination}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditDepartement;
