import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VehiculeInfoHistory = () => {
  const { id } = useParams();
  const [history, setHistory] = useState([]);

  useEffect(() => {
    fetchHistory();
  }, [id]);

  const fetchHistory = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vehiculeinfos/${id}/history`);
      setHistory(response.data);
    } catch (error) {
      toast.error('Error fetching history');
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 className="title">Vehicle Information History</h1>
      <h2 className="subtitle">History of Vehicle ID: {id}</h2>
      <Link to="/vehiculeinfos" className="button is-primary mb-2">
        Back to List
      </Link>
      <table className="table is-striped is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Genre</th>
            <th>Type</th>
            <th>Marque</th>
            <th>Modèle</th>
            <th>Année</th>
            <th>Numéro de Châssis</th>
            <th>Plaque</th>
            <th>Remorque</th>
            <th>Plaque de Remorque</th>
            <th>Source Énergie</th>
            <th>Puissance</th>
            <th>Nombre de Place</th>
            <th>Date de Circulation</th>
            <th>Assurance</th>
            <th>Type Assurance</th>
            <th>Validité Assurance</th>
            <th>Fiche Technique</th>
            <th>Validité Fiche Technique</th>
            <th>Permis</th>
            <th>Type de Permis</th>
            <th>Validité Permis</th>
            <th>Catégorie</th>
            <th>Info Propriétaire</th>
            <th>Vitre Fumée</th>
            <th>Validité Vitre Fumée</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {history.map((record, index) => (
            <tr key={record.id}>
              <td>{index + 1}</td>
              <td>{record.genre}</td>
              <td>{record.type}</td>
              <td>{record.marque}</td>
              <td>{record.modele}</td>
              <td>{record.annee}</td>
              <td>{record.numero_de_chasie}</td>
              <td>{record.plaque}</td>
              <td>{record.remorque}</td>
              <td>{record.plaque_de_remorque}</td>
              <td>{record.source_energie}</td>
              <td>{record.puissance}</td>
              <td>{record.nombre_de_place}</td>
              <td>{new Date(record.date_de_circulation).toLocaleString()}</td>
              <td>{record.assurance}</td>
              <td>{record.type_assurance}</td>
              <td>{new Date(record.validite_assurance).toLocaleString()}</td>
              <td>{record.fiche_technique}</td>
              <td>{new Date(record.validite_fiche_technique).toLocaleString()}</td>
              <td>{record.permis}</td>
              <td>{record.type_de_permis ? record.type_de_permis : 'N/A'}</td> {/* Added fallback to check */}
              <td>{new Date(record.validite_permis).toLocaleString()}</td>
              <td>{record.categorie}</td>
              <td>{record.info_proprietaire}</td>
              <td>{record.vitrefumee}</td>
              <td>{new Date(record.validite_vitrefumee).toLocaleString()}</td>
              <td>{new Date(record.updatedAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VehiculeInfoHistory;
