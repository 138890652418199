import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormEditVehiculeInfo = () => {
  const [formData, setFormData] = useState({
    genre: '',
    type: '',
    marque: '',
    modele: '',
    annee: '',
    numero_de_chasie: '',
    plaque: '',
    remorque: 'non',
    plaque_de_remorque: '',
    source_energie: '',
    puissance: '',
    nombre_de_place: '',
    date_de_circulation: '',
    assurance: 'non',
    type_assurance: '',
    validite_assurance: '',
    fiche_technique: 'non',
    validite_fiche_technique: '',
    permis: 'non',
    type_de_permis: '',
    validite_permis: '',
    categorie: '',
    info_proprietaire: '',
    vitrefumee: 'non',
    validite_vitrefumee: ''
  });
  const [vignettes, setVignettes] = useState([]);
  const [personalInfos, setPersonalInfos] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchVehiculeInfo();
    fetchVignettes();
    fetchPersonalInfos();
  }, [id]);

  const fetchVehiculeInfo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vehiculeinfos/${id}`);
      setFormData(response.data);
    } catch (error) {
      toast.error('Error fetching vehicle information');
    }
  };

  const fetchVignettes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vignettes`);
      setVignettes(response.data);
    } catch (error) {
      toast.error('Error fetching vignettes');
    }
  };

  const fetchPersonalInfos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/personalinfos`);
      setPersonalInfos(response.data);
    } catch (error) {
      toast.error('Error fetching personal infos');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/vehiculeinfos/${id}`, formData);
      toast.success('Vehicle information updated successfully');
      navigate('/vehiculeinfos');
    } catch (error) {
      toast.error('Error updating vehicle information');
    }
  };

  return (
    <div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <h1 className="title">Edit Vehicle Information</h1>
      <h2 className="subtitle">Edit Existing Vehicle Information</h2>
      <form onSubmit={handleSubmit}>
        {/* Read-only fields */}
        

        {/* Editable fields */}
        <div className="field">
          <label className="label">Assurance</label>
          <div className="control">
            <select name="assurance" value={formData.assurance} onChange={handleChange} required className="input">
              <option value="non">Non</option>
              <option value="oui">Oui</option>
            </select>
          </div>
        </div>
        {formData.assurance === "oui" && (
          <>
            <div className="field">
              <label className="label">Type d'Assurance</label>
              <div className="control">
                <select name="type_assurance" value={formData.type_assurance} onChange={handleChange} className="input">
                  <option value="">Select Type</option>
                  <option value="garantie au tiers simple">Garantie au tiers simple</option>
                  <option value="au tiers étendu">Au tiers étendu</option>
                  <option value="la garantie tous risques">La garantie tous risques</option>
                </select>
              </div>
            </div>
            <div className="field">
              <label className="label">Validité de l'Assurance</label>
              <div className="control">
                <input type="date" name="validite_assurance" value={formData.validite_assurance} onChange={handleChange} className="input" />
              </div>
            </div>
          </>
        )}
        <div className="field">
          <label className="label">Fiche Technique</label>
          <div className="control">
            <select name="fiche_technique" value={formData.fiche_technique} onChange={handleChange} required className="input">
              <option value="non">Non</option>
              <option value="oui">Oui</option>
            </select>
          </div>
        </div>
        {formData.fiche_technique === "oui" && (
          <div className="field">
            <label className="label">Validité de la Fiche Technique</label>
            <div className="control">
              <input type="date" name="validite_fiche_technique" value={formData.validite_fiche_technique} onChange={handleChange} className="input" />
            </div>
          </div>
        )}
        <div className="field">
          <label className="label">Permis</label>
          <div className="control">
            <select name="permis" value={formData.permis} onChange={handleChange} required className="input">
              <option value="non">Non</option>
              <option value="oui">Oui</option>
            </select>
          </div>
        </div>
        {formData.permis === "oui" && (
          <>
            <div className="field">
              <label className="label">Type de Permis</label>
              <div className="control">
                <select name="type_de_permis" value={formData.type_de_permis} onChange={handleChange} className="input">
                  <option value="">Select Type</option>
                  <option value="A1">A1</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="E">E</option>
                  <option value="F">F</option>
                  <option value="G">G</option>
                </select>
              </div>
            </div>
            <div className="field">
              <label className="label">Validité du Permis</label>
              <div className="control">
                <input type="date" name="validite_permis" value={formData.validite_permis} onChange={handleChange} className="input" />
              </div>
            </div>
          </>
        )}
        <div className="field">
          <label className="label">Catégorie</label>
          <div className="control">
            <select name="categorie" value={formData.categorie} onChange={handleChange} required className="input">
              <option value="">Select Catégorie</option>
              {vignettes.map((vignette) => (
                <option key={vignette.id} value={vignette.id}>{vignette.description}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="field">
          <label className="label">Info Propriétaire (NNI)</label>
          <div className="control">
            <select name="info_proprietaire" value={formData.info_proprietaire} onChange={handleChange} required className="input">
              <option value="">Select Propriétaire</option>
              {personalInfos.map((info) => (
                <option key={info.nni} value={info.nni}>{info.nom} - {info.nni}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="field">
          <label className="label">Vitre Fumée</label>
          <div className="control">
            <select name="vitrefumee" value={formData.vitrefumee} onChange={handleChange} required className="input">
              <option value="non">Non</option>
              <option value="oui">Oui</option>
            </select>
          </div>
        </div>
        {formData.vitrefumee === "oui" && (
          <div className="field">
            <label className="label">Validité de la Vitre Fumée</label>
            <div className="control">
              <input type="date" name="validite_vitrefumee" value={formData.validite_vitrefumee} onChange={handleChange} className="input" />
            </div>
          </div>
        )}
        <div className="field">
          <div className="control">
            <button type="submit" className="button is-success">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormEditVehiculeInfo;
