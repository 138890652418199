import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditPersonalInfo = () => {
  const [nni, setNni] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [dob, setDob] = useState("");
  const [lieuDeNaissance, setLieuDeNaissance] = useState("");
  const [numeroDeTelephone, setNumeroDeTelephone] = useState("");
  const [numeroDeCarte, setNumeroDeCarte] = useState("");
  const [profession, setProfession] = useState("");
  const [provinceId, setProvinceId] = useState("");
  const [departementId, setDepartementId] = useState("");
  const [communeId, setCommuneId] = useState("");
  const [quartierId, setQuartierId] = useState("");
  const [rueId, setRueId] = useState("");
  const [porteId, setPorteId] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [departements, setDepartements] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [quartiers, setQuartiers] = useState([]);
  const [rues, setRues] = useState([]);
  const [portes, setPortes] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getPersonalInfoById();
    fetchProvinces();
    fetchDepartements();
    fetchCommunes();
    fetchQuartiers();
    fetchRues();
    fetchPortes();
  }, [id]);

  const getPersonalInfoById = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/personalinfos/${id}`);
      const data = response.data;
      setNni(data.nni);
      setNom(data.nom);
      setPrenom(data.prenom);
      setDob(data.dob);
      setLieuDeNaissance(data.lieu_de_naissance);
      setNumeroDeTelephone(data.numero_de_telephone);
      setNumeroDeCarte(data.numero_de_carte);
      setProfession(data.profession);
      setProvinceId(data.provinceId);
      setDepartementId(data.departementId);
      setCommuneId(data.communeId);
      setQuartierId(data.quartierId);
      setRueId(data.rueId);
      setPorteId(data.porteId);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const fetchProvinces = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/provinces`);
      setProvinces(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDepartements = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/departements`);
      setDepartements(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCommunes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/communes`);
      setCommunes(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchQuartiers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/quartiers`);
      setQuartiers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRues = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/rues`);
      setRues(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPortes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/portes`);
      setPortes(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const updatePersonalInfo = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/personalinfos/${id}`, {
        nni,
        nom,
        prenom,
        dob,
        lieu_de_naissance: lieuDeNaissance,
        numero_de_telephone: numeroDeTelephone,
        numero_de_carte: numeroDeCarte,
        profession,
        provinceId,
        departementId,
        communeId,
        quartierId,
        rueId,
        porteId,
      });
      navigate("/personalinfos");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Personal Info</h1>
      <h2 className="subtitle">Edit Personal Info</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updatePersonalInfo}>
              <p className="has-text-centered">{msg}</p>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">NNI</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={nni}
                        onChange={(e) => setNni(e.target.value)}
                        placeholder="NNI"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Nom</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={nom}
                        onChange={(e) => setNom(e.target.value)}
                        placeholder="Nom"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Prenom</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={prenom}
                        onChange={(e) => setPrenom(e.target.value)}
                        placeholder="Prenom"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Date De Naissance</label>
                    <div className="control">
                      <input
                        type="date"
                        className="input"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Lieu de Naissance</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={lieuDeNaissance}
                        onChange={(e) => setLieuDeNaissance(e.target.value)}
                        placeholder="Lieu de Naissance"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Numéro de Téléphone</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={numeroDeTelephone}
                        onChange={(e) => setNumeroDeTelephone(e.target.value)}
                        placeholder="Numéro de Téléphone"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Numéro de Carte</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={numeroDeCarte}
                        onChange={(e) => setNumeroDeCarte(e.target.value)}
                        placeholder="Numéro de Carte"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Profession</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        placeholder="Profession"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Province</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={provinceId}
                          onChange={(e) => setProvinceId(e.target.value)}
                          required
                        >
                          <option value="">Select Province</option>
                          {provinces.map((province) => (
                            <option key={province.id} value={province.id}>
                              {province.denomination}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Departement</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={departementId}
                          onChange={(e) => setDepartementId(e.target.value)}
                          required
                        >
                          <option value="">Select Departement</option>
                          {departements.map((departement) => (
                            <option key={departement.id} value={departement.id}>
                              {departement.denomination}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Commune</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={communeId}
                          onChange={(e) => setCommuneId(e.target.value)}
                          required
                        >
                          <option value="">Select Commune</option>
                          {communes.map((commune) => (
                            <option key={commune.id} value={commune.id}>
                              {commune.denomination}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Quartier</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={quartierId}
                          onChange={(e) => setQuartierId(e.target.value)}
                          required
                        >
                          <option value="">Select Quartier</option>
                          {quartiers.map((quartier) => (
                            <option key={quartier.id} value={quartier.id}>
                              {quartier.denomination}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Rue</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={rueId}
                          onChange={(e) => setRueId(e.target.value)}
                          required
                        >
                          <option value="">Select Rue</option>
                          {rues.map((rue) => (
                            <option key={rue.id} value={rue.id}>
                              {rue.denomination}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Porte</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={porteId}
                          onChange={(e) => setPorteId(e.target.value)}
                          required
                        >
                          <option value="">Select Porte</option>
                          {portes.map((porte) => (
                            <option key={porte.id} value={porte.id}>
                              {porte.numero_du_porte}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditPersonalInfo;
