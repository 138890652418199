import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Input, Pagination, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './VitreStatus.css';

const { Option } = Select;

const VignetteList = () => {
  const [vignettes, setVignettes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filteredVignettes, setFilteredVignettes] = useState([]);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredVignettes.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    getVignettes();
  }, []);

  useEffect(() => {
    filterVignettes();
  }, [searchValue, vignettes]);


const getVignettes = async () => {
    console.log("Fetching vignettes from:", `${process.env.REACT_APP_API_URL}/vignettes`);
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/vignettes`);
        
        // Check if response.data is an array
        if (Array.isArray(response.data)) {
            setVignettes(response.data);
        } else {
            console.error("Unexpected response format:", response.data);
            setVignettes([]); // Set to an empty array if the format is unexpected
        }
    } catch (error) {
        console.error("Error fetching vignettes:", error);
        setVignettes([]); // Set to an empty array on error
    }
};


  const deleteVignette = async (vignetteId) => {
    if (window.confirm("Are you sure that you want to delete?")) {
      await axios.delete(`${process.env.REACT_APP_API_URL}/${vignetteId}`);
      getVignettes();
    }
  };

const filterVignettes = () => {
    // Ensure vignettes is an array before filtering
    if (Array.isArray(vignettes)) {
        const filteredData = vignettes.filter((vignette) =>
            Object.values(vignette).some((field) =>
                field.toString().toLowerCase().includes(searchValue.toLowerCase())
            )
        );
        setFilteredVignettes(filteredData);
    } else {
        setFilteredVignettes([]); // If not an array, set filtered to empty
    }
};
  const handlePostsPerPageChange = (value) => {
    setPostsPerPage(value);
    setCurrentPage(1);
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 className="title">Vignettes</h1>
      <h2 className="subtitle">List Of Vignettes</h2>
      <Link to="/vignettes/add" className="button is-primary mb-2">
        Add New
      </Link>
      <Input
        placeholder="Search by any field"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<SearchOutlined />}
        style={{ width: '220px', height: '38px', marginBottom: '16px', marginLeft: '8px' }}
      />

      <table className="table is-striped is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Description</th>
            <th>Couleur</th>
            <th>Montant</th>
            {/* <th>Date Expiration</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((vignette, index) => (
            <tr key={vignette.id}>
              <td>{index + firstPostIndex + 1}</td>
              <td>{vignette.description}</td>
              <td>
                <span
                  style={{
                    display: 'inline-block',
                    width: '20px',
                    height: '20px',
                    backgroundColor: vignette.couleur,
                    borderRadius: '50%',
                  }}
                ></span>
              </td>
              <td>{vignette.montant}</td>
              {/* <td>{new Date(vignette.date_expiration).toLocaleDateString()}</td> */}
              <td>
                <Link
                  to={`/vignettes/edit/${vignette.id}`}
                  className="button is-small is-info mr-1"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deleteVignette(vignette.id)}
                  className="button is-small is-danger mr-1"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <div style={{ marginRight: '8px' }}>
          <Select
            value={postsPerPage}
            onChange={handlePostsPerPageChange}
            style={{ width: '80px' }}
          >
            <Option value={10}>10</Option>
            <Option value={25}>25</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Pagination
            current={currentPage}
            pageSize={postsPerPage}
            total={filteredVignettes.length}
            onChange={(page) => setCurrentPage(page)}
            style={{ textAlign: 'right' }}
          />
        </div>
      </div>
    </div>
  );
};

export default VignetteList;
