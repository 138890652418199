import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./FormAddPersonalInfo.css";

const FormAddVehiculeInfo = () => {
  const [formData, setFormData] = useState({
    genre: "",
    type: "",
    marque: "",
    modele: "",
    annee: "",
    numero_de_chasie: "",
    plaque: "",
    remorque: "non",
    plaque_de_remorque: "",
    source_energie: "",
    puissance: "",
    nombre_de_place: 1,
    date_de_circulation: "",
    assurance: "non",
    type_assurance: "",
    validite_assurance: "",
    fiche_technique: "non",
    validite_fiche_technique: "",
    permis: "non",
    type_de_permis: "",
    validite_permis: "",
    categorie: "",
    info_proprietaire: "",
    vitrefumee: 'non',
    validite_vitrefumee: ''
  });

  const [vignettes, setVignettes] = useState([]);
  const [personalInfos, setPersonalInfos] = useState([]);
  const [filteredPersonalInfos, setFilteredPersonalInfos] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchVignettes();
    fetchPersonalInfos();
  }, []);

  useEffect(() => {
    setFilteredPersonalInfos(
      personalInfos.filter((info) =>
        info.nni.includes(searchQuery) || info.numero_de_telephone.includes(searchQuery)
      )
    );
  }, [searchQuery, personalInfos]);

  const fetchVignettes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vignettes`);
      setVignettes(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPersonalInfos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/personalinfos`);
      setPersonalInfos(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

//   const saveVehiculeInfo = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post(`${process.env.REACT_APP_API_URL}/vehiculeinfos`, formData);
//       navigate("/vehiculeinfos");
//     } catch (error) {
//       if (error.response) {
//         setMsg(error.response.data.msg);
//       }
//     }
//   };


const saveVehiculeInfo = async (e) => {
    e.preventDefault();

    // Set default values for optional fields
    const dataToSend = { ...formData };
    if (formData.assurance === "non") {
      dataToSend.type_assurance = "";
      dataToSend.validite_assurance = null;
    }
    if (formData.fiche_technique === "non") {
      dataToSend.validite_fiche_technique = null;
    }
    if (formData.permis === "non") {
      dataToSend.type_de_permis = "";
      dataToSend.validite_permis = null;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/vehiculeinfos`, dataToSend);
      navigate("/vehiculeinfos");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };


  return (
    <div>
      <h1 className="title">Informations sur le véhicule</h1>
      <h2 className="subtitle">Ajouter de nouvelles informations sur le véhicule</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveVehiculeInfo}>
              <p className="has-text-centered">{msg}</p>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Genre</label>
                    <div className="control">
                      <select name="genre" value={formData.genre} onChange={handleChange} required className="input">
                        <option value="">Select Genre</option>
                        <option value="moto">Moto</option>
                        <option value="voiture">Voiture</option>
                        <option value="camion">Camion</option>
                        <option value="Tracteurs agricoles">Tracteurs agricoles</option>
                        <option value="autre">Autre</option>
                      </select>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Type</label>
                    <div className="control">
                      <select name="type" value={formData.type} onChange={handleChange} required className="input">
                        <option value="">Select Type</option>
                        <option value="camion">Camion</option>
                        <option value="coupe">Coupe</option>
                        <option value="moto 2 roues">Moto 2 roues</option>
                        <option value="moto tricycle">Moto tricycle</option>
                        <option value="berline">Berline</option>
                        <option value="moto 4 roues">Moto 4 roues</option>
                        <option value="remorque">Remorque</option>
                        <option value="semi remorque">Semi remorque</option>
                        <option value="autre">Autre</option>
                      </select>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Marque</label>
                    <div className="control">
                      <select name="marque" value={formData.marque} onChange={handleChange} required className="input">
                        <option value="">Select Marque</option>
                        <option value="Peugeot">Peugeot</option>
                        <option value="Renault">Renault</option>
                        <option value="Citroën">Citroën</option>
                        <option value="Dacia">Dacia</option>
                        <option value="Volkswagen">Volkswagen</option>
                        <option value="Toyota">Toyota</option>
                        <option value="Mercedes">Mercedes</option>
                        <option value="Audi">Audi</option>
                        <option value="BMW">BMW</option>
                        <option value="Hyundai">Hyundai</option>
                        <option value="HONDA">HONDA</option>
                        <option value="YAMAHA">YAMAHA</option>
                        <option value="autre">Autre</option>
                      </select>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Modele</label>
                    <div className="control">
                      <input type="text" name="modele" value={formData.modele} onChange={handleChange} required className="input" placeholder="Modele" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Année</label>
                    <div className="control">
                      <select name="annee" value={formData.annee} onChange={handleChange} required className="input">
                        <option value="">Select Année</option>
                        {Array.from({ length: 35 }, (_, i) => 1990 + i).map((year) => (
                          <option key={year} value={year}>{year}</option>
                        ))}
                        <option value="autre">Autre</option>
                      </select>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Numéro de Chasie</label>
                    <div className="control">
                      <input type="text" name="numero_de_chasie" value={formData.numero_de_chasie} onChange={handleChange} required className="input" placeholder="Numéro de Chasie" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Plaque</label>
                    <div className="control">
                      <input type="text" name="plaque" value={formData.plaque} onChange={handleChange} required className="input" placeholder="Plaque" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Remorque</label>
                    <div className="control">
                      <select name="remorque" value={formData.remorque} onChange={handleChange} required className="input">
                        <option value="non">Non</option>
                        <option value="oui">Oui</option>
                      </select>
                    </div>
                  </div>
                  {formData.remorque === "oui" && (
                    <div className="field">
                      <label className="label">Plaque de Remorque</label>
                      <div className="control">
                        <input type="text" name="plaque_de_remorque" value={formData.plaque_de_remorque} onChange={handleChange} className="input" placeholder="Plaque de Remorque" />
                      </div>
                    </div>
                  )}

<div className="field">
                    <label className="label">Info Propriétaire (NNI)</label>
                    <div className="control">
                      <input type="text" placeholder="Search by NNI or phone" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="input" />
                      <select name="info_proprietaire" value={formData.info_proprietaire} onChange={handleChange} required className="input">
                        <option value="">Select Propriétaire</option>
                        {filteredPersonalInfos.map((info) => (
                          <option key={info.nni} value={info.nni}>{info.nom} - {info.nni}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="column">
             
                  <div className="field">
                    <label className="label">Source d'énergie</label>
                    <div className="control">
                      <select name="source_energie" value={formData.source_energie} onChange={handleChange} required className="input">
                        <option value="">Select Source</option>
                        <option value="gasoil">Gasoil</option>
                        <option value="essence">Essence</option>
                        <option value="hybride">Hybride</option>
                        <option value="electrique">Electrique</option>
                      </select>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Puissance</label>
                    <div className="control">
                      <input type="text" name="puissance" value={formData.puissance} onChange={handleChange} required className="input" placeholder="Puissance" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Nombre de Place</label>
                    <div className="control">
                      <input type="number" name="nombre_de_place" value={formData.nombre_de_place} onChange={handleChange} required className="input" placeholder="Nombre de Place" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Date de Circulation</label>
                    <div className="control">
                      <input type="date" name="date_de_circulation" value={formData.date_de_circulation} onChange={handleChange} required className="input" />
                    </div>
                  </div>
                  <div className="field">
            <label className="label">Assurance</label>
            <div className="control">
              <select name="assurance" value={formData.assurance} onChange={handleChange} required className="input">
                <option value="non">Non</option>
                <option value="oui">Oui</option>
              </select>
            </div>
          </div>
          {formData.assurance === "oui" && (
            <>
              <div className="field">
                <label className="label">Type d'Assurance</label>
                <div className="control">
                  <select name="type_assurance" value={formData.type_assurance} onChange={handleChange} className="input">
                    <option value="">Select Type</option>
                    <option value="garantie au tiers simple">Garantie au tiers simple</option>
                    <option value="au tiers étendu">Au tiers étendu</option>
                    <option value="la garantie tous risques">La garantie tous risques</option>
                  </select>
                </div>
              </div>
              <div className="field">
                <label className="label">Validité de l'Assurance</label>
                <div className="control">
                  <input type="date" name="validite_assurance" value={formData.validite_assurance} onChange={handleChange} className="input" />
                </div>
              </div>
            </>
          )}
                  <div className="field">
                    <label className="label">Fiche Technique</label>
                    <div className="control">
                      <select name="fiche_technique" value={formData.fiche_technique} onChange={handleChange} required className="input">
                        <option value="non">Non</option>
                        <option value="oui">Oui</option>
                      </select>
                    </div>
                  </div>
                  {formData.fiche_technique === "oui" && (
                    <div className="field">
                      <label className="label">Validité de la Fiche Technique</label>
                      <div className="control">
                        <input type="date" name="validite_fiche_technique" value={formData.validite_fiche_technique} onChange={handleChange} className="input" />
                      </div>
                    </div>
                  )}
                  <div className="field">
                    <label className="label">Permis</label>
                    <div className="control">
                      <select name="permis" value={formData.permis} onChange={handleChange} required className="input">
                        <option value="non">Non</option>
                        <option value="oui">Oui</option>
                      </select>
                    </div>
                  </div>
                  {formData.permis === "oui" && (
                    <>
                      <div className="field">
                        <label className="label">Type de Permis</label>
                        <div className="control">
                          <select name="type_de_permis" value={formData.type_de_permis} onChange={handleChange} className="input">
                            <option value="">Select Type</option>
                            <option value="A1">A1</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="G">G</option>
                          </select>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Validité du Permis</label>
                        <div className="control">
                          <input type="date" name="validite_permis" value={formData.validite_permis} onChange={handleChange} className="input" />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="field">
                    <label className="label">Vitres Fumées</label>
                    <div className="control">
                      <select name="vitrefumee" value={formData.vitrefumee} onChange={handleChange} required className="input">
                        <option value="non">Non</option>
                        <option value="oui">Oui</option>
                      </select>
                    </div>
                  </div>
                  {formData.vitrefumee === "oui" && (
                    <div className="field">
                      <label className="label">Validité des Vitres Fumées</label>
                      <div className="control">
                        <input type="date" name="validite_vitrefumee" value={formData.validite_vitrefumee} onChange={handleChange} className="input" />
                      </div>
                    </div>
                  )}
                  <div className="field">
                    <label className="label">Catégorie</label>
                    <div className="control">
                      <select name="categorie" value={formData.categorie} onChange={handleChange} required className="input">
                        <option value="">Select Catégorie</option>
                        {vignettes.map((vignette) => (
                          <option key={vignette.id} value={vignette.id}>{vignette.description}</option>
                        ))}
                      </select>
                    </div>
                  </div>
             
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                      Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddVehiculeInfo;
