import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditPorte = () => {
  const [numeroDuPorte, setNumeroDuPorte] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [rueId, setRueId] = useState("");
  const [nomDuProprietaire, setNomDuProprietaire] = useState("");
  const [rues, setRues] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getPorteById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/portes/${id}`);
        setNumeroDuPorte(response.data.numero_du_porte);
        setLongitude(response.data.longitude);
        setLatitude(response.data.latitude);
        setRueId(response.data.rueId);
        setNomDuProprietaire(response.data.nom_du_proprietaire);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getPorteById();
  }, [id]);

  useEffect(() => {
    const fetchRues = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/rues`);
        setRues(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchRues();
  }, []);

  const updatePorte = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/portes/${id}`, {
        numero_du_porte: numeroDuPorte,
        longitude,
        latitude,
        rueId,
        nom_du_proprietaire: nomDuProprietaire,
      });
      navigate("/portes");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Portes</h1>
      <h2 className="subtitle">Edit Porte</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updatePorte}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Numéro du Porte</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={numeroDuPorte}
                    onChange={(e) => setNumeroDuPorte(e.target.value)}
                    placeholder="Numéro du Porte"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Longitude</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    placeholder="Longitude"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Latitude</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    placeholder="Latitude"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Rue</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={rueId}
                      onChange={(e) => setRueId(e.target.value)}
                    >
                      <option value="">Select Rue</option>
                      {rues.map((rue) => (
                        <option key={rue.id} value={rue.id}>
                          {rue.denomination}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Nom du Propriétaire</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={nomDuProprietaire}
                    onChange={(e) => setNomDuProprietaire(e.target.value)}
                    placeholder="Nom du Propriétaire"
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditPorte;
