import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddProvince = () => {
  const [code, setCode] = useState("");
  const [denomination, setDenomination] = useState("");
  const [codeIso, setCodeIso] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [superficie, setSuperficie] = useState("");
  const [nombreDeDepartement, setNombreDeDepartement] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const saveProvince = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/provinces`, {
        code,
        denomination,
        code_iso: codeIso,
        code_postal: codePostal,
        superficie,
        nombre_de_departement: nombreDeDepartement,
      });
      navigate("/provinces");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Provinces</h1>
      <h2 className="subtitle">Add New Province</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveProvince}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Code</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Code"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Denomination</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={denomination}
                    onChange={(e) => setDenomination(e.target.value)}
                    placeholder="Denomination"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Code ISO</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codeIso}
                    onChange={(e) => setCodeIso(e.target.value)}
                    placeholder="Code ISO"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Code Postal</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codePostal}
                    onChange={(e) => setCodePostal(e.target.value)}
                    placeholder="Code Postal"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Superficie</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={superficie}
                    onChange={(e) => setSuperficie(e.target.value)}
                    placeholder="Superficie"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nombre de Departement</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={nombreDeDepartement}
                    onChange={(e) => setNombreDeDepartement(e.target.value)}
                    placeholder="Nombre de Departement"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddProvince;
