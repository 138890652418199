import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddAmande = () => {
  const [plaque, setPlaque] = useState("");
  const [PTypeId, setPTypeId] = useState(""); // Store the ID only
  const [status, setStatus] = useState();
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [image, setImage] = useState(null);
  const [penalties, setPenalties] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchPenalties();
  }, []);

  const fetchPenalties = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/penalties`);
      setPenalties(response.data);
    } catch (error) {
      console.error("Error fetching penalties:", error);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handlePTypeIdChange = (e) => {
    const selectedValue = e.target.value;
    console.log("Selected PTypeId (Penalty ID):", selectedValue); // This should log only the penalty ID
    setPTypeId(selectedValue);
  };

  const saveAmande = async (e) => {
    e.preventDefault();

    const payload = {
      plaque,
      PTypeId,
      status,
      lat,
      long,
      image: image ? URL.createObjectURL(image) : null,  // Example: handling images
    };

    console.log("Payload:", payload);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/amandes`, payload);  // Send as JSON instead of FormData
      navigate("/amandes");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
};

  return (
    <div>
      <h1 className="title">Amandes</h1>
      <h2 className="subtitle">Ajouter Une Nouvelle Amande</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveAmande}>
              <p className="has-text-centered" style={{ color: 'red' }}>{msg}</p>
              <div className="field">
                <label className="label">Plaque</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={plaque}
                    onChange={(e) => setPlaque(e.target.value)}
                    placeholder="Numéro de Plaque"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Type d'Amande</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={PTypeId}
                      onChange={handlePTypeIdChange}
                      required
                    >
                      <option value="">Sélectionnez un type d'amande</option>
                      {penalties.map((penalty) => (
                        <option key={penalty.id} value={penalty.id}>
                          {penalty.description} - {penalty.amount} 
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Latitude</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={lat}
                    onChange={(e) => setLat(e.target.value)}
                    placeholder="Latitude"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Longitude</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={long}
                    onChange={(e) => setLong(e.target.value)}
                    placeholder="Longitude"
                    required
                  />
                </div>
              </div>

              {/* Uncomment if image upload is needed */}
              {/* <div className="field">
                <label className="label">Image</label>
                <div className="control">
                  <input type="file" onChange={handleImageChange} />
                </div>
              </div> */}

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddAmande;
