// components/FormEditVignette.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { SketchPicker } from "react-color";

const FormEditVignette = () => {
  const [description, setDescription] = useState("");
  const [couleur, setCouleur] = useState("#fff");
  const [montant, setMontant] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getVignetteById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/vignettes/${id}`);
        setDescription(response.data.description);
        setCouleur(response.data.couleur);
        setMontant(response.data.montant);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getVignetteById();
  }, [id]);

  const updateVignette = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/vignettes/${id}`, {
        description,
        couleur,
        montant,
      });
      navigate("/vignettes");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Vignettes</h1>
      <h2 className="subtitle">Edit Vignette</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateVignette}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Description</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    >
                      <option value="">Select Description</option>
                      <option value="transport commun urbain">Transport Commun Urbain</option>
                      <option value="transport commun interurbain">Transport Commun Interurbain</option>
                      <option value="gros porteur">Gros Porteur</option>
                      <option value="camion">Camion</option>
                      <option value="vehicule">Vehicule</option>
                      <option value="moto_tricycle">Moto Tricycle</option>
                      <option value="moto">Moto</option>
                      <option value="TT">TT</option>
                      <option value="vip">VIP</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Couleur</label>
                <div className="control">
                  <SketchPicker
                    color={couleur}
                    onChangeComplete={(color) => setCouleur(color.hex)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Montant</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={montant}
                    onChange={(e) => setMontant(e.target.value)}
                    placeholder="Montant"
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditVignette;
